.AddProject {
    max-width: 600px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-between;
}

.AddProject div:nth-of-type(2){
    height: 5rem;
}
.AddProject div:nth-of-type(2) .InputForm {
    padding: .3rem 0 .3rem;
}
.LabelForm {
    position: relative;
    display: block;
    width: 100%;
    font-size: 1rem;
    text-align: left;
    margin-top: .6rem;
    padding: .3rem 0 .3rem;
    font-family:Calibri, sans-serif;
}
.InputForm {
    line-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60%;
    font-size: 1rem;
    font-family:Calibri, sans-serif;
}
.DivDataForm {
    line-height: 100%;
    position: absolute;
    left: 40%;
    top: 0;
    bottom: 0;
    color: #426b03;
}

.ButtonForm {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    background-color: #282c34;
    color: #ffc628!important;
    margin-top: 2rem;
    padding: 5px;
}

.ButtonForm .unactive {
    background-color: #ffffff;
    color: gray;
}
.card {
  height: fit-content;
}

.Content {
    flex-grow: 2;
}

.footer {
    /*position: absolute;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*background-color: black;*/
    /*color: white;*/
}

#root {
    /*height: 100vh;*/
    /*background-image: url('tlo.jpg');*/
    background-size: cover;
    background-repeat: no-repeat;

}

.App-div {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
